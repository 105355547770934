export function darktheme() {
	return {
		general: {
			color: '#fff',
			colorButtonClear: '#fff',
			colorButton: '#fff',
			backgroundColorButton: '#1976d2',
			backgroundInput: '#202223',
			colorPlaceholder: '#596269',
			colorCaret: '#fff',
			colorSpinner: '#fff',
			borderStyle: 'none',
			backgroundScrollIcon: '#fff'
		},

		container: {
			border: 'none',
			borderRadius: '4px',
			boxShadow:
				'0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
		},

		header: {
			background: '#181a1b',
			colorRoomName: '#fff',
			colorRoomInfo: '#9ca6af'
		},

		footer: {
			background: '#131415',
			borderStyleInput: 'none',
			borderInputSelected: '#1976d2',
			backgroundReply: '#1b1c1c',
			backgroundTagActive: '#1b1c1c',
			backgroundTag: '#131415'
		},

		content: {
			background: '#131415'
		},

		sidemenu: {
			background: '#181a1b',
			backgroundHover: '#202224',
			backgroundActive: '#151617',
			colorActive: '#fff',
			borderColorSearch: '#181a1b'
		},

		dropdown: {
			background: '#2a2c33',
			backgroundHover: '#26282e'
		},

		message: {
			background: '#22242a',
			backgroundMe: '#1f7e80',
			color: '#fff',
			colorStarted: '#9ca6af',
			backgroundDeleted: '#1b1c21',
			backgroundSelected: '#c2dcf2',
			colorDeleted: '#a2a5a8',
			colorUsername: '#b3bac9',
			colorTimestamp: '#ebedf2',
			backgroundDate: 'rgba(0, 0, 0, 0.3)',
			colorDate: '#bec5cc',
			backgroundSystem: 'rgba(0, 0, 0, 0.3)',
			colorSystem: '#bec5cc',
			backgroundMedia: 'rgba(0, 0, 0, 0.18)',
			backgroundReply: 'rgba(0, 0, 0, 0.18)',
			colorReplyUsername: '#fff',
			colorReply: '#d6d6d6',
			colorTag: '#f0c60a',
			backgroundImage: '#ddd',
			colorNewMessages: '#fff',
			backgroundScrollCounter: '#1976d2',
			colorScrollCounter: '#fff',
			backgroundReaction: 'none',
			borderStyleReaction: 'none',
			backgroundReactionHover: '#202223',
			borderStyleReactionHover: 'none',
			colorReactionCounter: '#fff',
			backgroundReactionMe: '#4e9ad1',
			borderStyleReactionMe: 'none',
			backgroundReactionHoverMe: '#4e9ad1',
			borderStyleReactionHoverMe: 'none',
			colorReactionCounterMe: '#fff',
			backgroundAudioRecord: '#eb4034',
			backgroundAudioLine: 'rgba(255, 255, 255, 0.15)',
			backgroundAudioProgress: '#b7d4d3',
			backgroundAudioProgressSelector: '#b7d4d3',
			colorFileExtension: '#a2a5a8'
		},

		markdown: {
			background: 'rgba(239, 239, 239, 0.7)',
			border: 'rgba(212, 212, 212, 0.9)',
			color: '#e01e5a',
			colorMulti: '#0a0a0a'
		},

		room: {
			colorUsername: '#fff',
			colorMessage: '#6c7278',
			colorTimestamp: '#6c7278',
			colorStateOnline: '#4caf50',
			colorStateOffline: '#596269',
			backgroundCounterBadge: '#1976d2',
			colorCounterBadge: '#fff'
		},

		emoji: {
			background: '#343740'
		},

		icons: {
			search: '#596269',
			add: '#fff',
			toggle: '#fff',
			menu: '#fff',
			close: '#9ca6af',
			closeImage: '#fff',
			file: '#1976d2',
			paperclip: '#fff',
			closeOutline: '#fff',
			closePreview: '#fff',
			send: '#fff',
			sendDisabled: '#646a70',
			emoji: '#fff',
			emojiReaction: '#fff',
			document: '#1976d2',
			pencil: '#ebedf2',
			checkmark: '#ebedf2',
			checkmarkSeen: '#f0d90a',
			eye: '#fff',
			dropdownMessage: '#fff',
			dropdownMessageBackground: 'rgba(0, 0, 0, 0.25)',
			dropdownRoom: '#fff',
			dropdownScroll: '#0a0a0a',
			microphone: '#fff',
			audioPlay: '#b7d4d3',
			audioPause: '#b7d4d3',
			audioCancel: '#eb4034',
			audioConfirm: '#1ba65b'
		}
	}
}
